import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import axiosConfig from "../utils/axiosConfig";
import {localizeDate, notify} from "../utils/utils";
import {useAuth} from "../../context/AuthContext";
import Loading from "../partial/Loading";

const BookingDetailModal = ({contingent, openedModals}) => {
    const auth = useAuth();

    const [isLoading, setIsLoading] = useState(true);
    const [bookings, setBookings] = useState([]);
    const [flightData, setFlightData] = useState([]);
    const [manager, setManager] = useState([]);
    const [modalId] = useState(`details-${contingent.VORGANG_ID}`);

    const load = async () => {
        return await axiosConfig.get(`/protected/agency/${auth.user.Kundennummer}/booked/${contingent.VORGANG_ID}`, {
            "headers": {
                "Authorization": `Bearer ${auth.token}`,
                "Content-Type": "application/json"
            },
        });
    }

    useEffect(() => {
        if (openedModals.includes(modalId)) {
            load().then((result) => {
                setIsLoading(false);
                setManager(result.data.manager)
                setBookings(result.data.books)
                setFlightData(result.data.flight)
            }).catch((e) => {
                notify(e.message)
                setManager(null)
                setBookings([])
                setFlightData([])
                setIsLoading(false);
            })
        }
    }, [openedModals])

    return (
        <div className="modal fade" id={modalId} tabIndex="-1"
             aria-labelledby="deatailsLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="deatailsLabel">Buchungsdetails</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <Loading isLoading={isLoading}/>
                        <h1> {contingent.Destination} - {contingent.Reisetitel}
                        </h1>
                        <h1>
                            Abreise am {localizeDate(contingent.Abflugtermin)} ab {contingent.Abflughafen} -
                            Kontingent-Nr.: {contingent.VORGANGNR}
                        </h1>
                        <p>Ihre GLOBALIS-Ansprechpartner:<br/>
                            <b>Name:</b> {manager?.Vorname} {manager?.Name}<br/>
                            <b>Telefon:</b> {manager?.Telefon} <br/>
                            <b>E_Mail:</b> {manager?.email} </p>
                        <h1>Flugdaten</h1>
                        <p><i className="fa fa-info-circle"></i> Die genauen Flugzeiten/-daten erhalten Sie mit den
                            Reiseunterlagen.</p>
                        <table className="table table-responsive table-striped">
                            <thead>
                            <tr>
                                <th>Hinflug</th>
                                <td>Fluggesellschaft</td>
                                <td>Flugnummer</td>
                                <td>Flugstrecke</td>
                                <td>Ab</td>
                                <td>An</td>
                            </tr>
                            </thead>
                            <tbody>
                            {!flightData.outboundStop ? (
                                <tr>
                                    <td>{localizeDate(flightData.outboundDepartureFlightDate)}</td>
                                    <td>{flightData.outboundAirline}</td>
                                    <td>{flightData.outboundFlightNr}</td>
                                    <td>{flightData.outboundDepartureFlightAirportName} ({flightData.outboundDepartureFlightAirport})
                                        - {flightData.outboundArrivalFlightAirportName} ({flightData.outboundArrivalFlightAirport})
                                    </td>
                                    <td>{flightData.outboundDepartureFlightStartTime}</td>
                                    <td>{flightData.outboundDepartureFlightEndTime}</td>
                                </tr>
                            ) : (
                                <>
                                    <tr>
                                        <td>{localizeDate(flightData.outboundDepartureFlightDate)}</td>
                                        <td>{flightData.outboundAirline}</td>
                                        <td>{flightData.outboundDepartureFlightNr}</td>
                                        <td>{flightData.outboundDepartureFlightAirportName} ({flightData.outboundDepartureFlightAirport})
                                            - {flightData.outboundStopDepartureFlightAirportName} ({flightData.outboundStopDepartureFlightAirport})
                                        </td>
                                        <td>{flightData.outboundDepartureFlightStartTime}</td>
                                        <td>{flightData.outboundStopArrivalFlightTime}</td>
                                    </tr>
                                    <tr>
                                        <td>{localizeDate(flightData.outboundStopDepartureFlightDate)}</td>
                                        <td>{flightData.outboundAirline}</td>
                                        <td>{flightData.outboundStopFlightNr}</td>
                                        <td>{flightData.outboundStopDepartureFlightAirportName} ({flightData.outboundStopDepartureFlightAirport})
                                            - {flightData.outboundStopArrivalFlightAirportName} ({flightData.outboundStopArrivalFlightAirport})
                                        </td>
                                        <td>{flightData.outboundStopDepartureFlightTime}</td>
                                        <td>{flightData.outboundArrivalFlightTime}</td>
                                    </tr>
                                </>
                            )
                            }
                            </tbody>
                        </table>
                        <table className="table table-responsive table-striped">
                            <thead>
                            <tr>
                                <th>Rückflug</th>
                                <td>Fluggesellschaft</td>
                                <td>Flugnummer</td>
                                <td>Flugstrecke</td>
                                <td>Ab</td>
                                <td>An</td>
                            </tr>
                            </thead>
                            <tbody>

                            {!flightData.returnStop ? (
                                <tr>
                                    <td>{localizeDate(flightData.returnDepartureFlightDate)}</td>
                                    <td>{flightData.returnAirline}</td>
                                    <td>{flightData.returnDepartureFlightNr}</td>
                                    <td>{flightData.returnDepartureFlightAirportName} ({flightData.returnDepartureFlightAirport})
                                        - {flightData.returnArrivalFlightAirportName} ({flightData.returnArrivalFlightAirport})
                                    </td>
                                    <td>{flightData.returnArrivalFlightStartTime}</td>
                                    <td>{flightData.returnArrivalFlightEndTime}</td>
                                </tr>
                            ) : (
                                <>
                                    <tr>
                                        <td>{localizeDate(flightData.returnDepartureFlightDate)}</td>
                                        <td>{flightData.returnAirline}</td>
                                        <td>{flightData.returnFlightNr}</td>
                                        <td>{flightData.returnDepartureFlightAirportName} ({flightData.returnDepartureFlightAirport})
                                            - {flightData.returnStopDepartureFlightAirportName} ({flightData.returnStopDepartureFlightAirport})
                                        </td>
                                        <td>{flightData.returnDepartureFlightTime}</td>
                                        <td>{flightData.returnStopArrivalFlightTime}</td>
                                    </tr>
                                    <tr>
                                        <td>{localizeDate(flightData.returnArrivalFlightDate)}</td>
                                        <td>{flightData.returnAirline}</td>
                                        <td>{flightData.returnStopFlightNr}</td>
                                        <td>{flightData.returnStopArrivalFlightAirportName} ({flightData.returnStopArrivalFlightAirport})
                                            - {flightData.returnArrivalFlightAirportName} ({flightData.returnArrivalFlightAirport})
                                        </td>
                                        <td>{flightData.returnStopDepartureFlightTime}</td>
                                        <td>{flightData.returnArrivalFlightEndTime}</td>
                                    </tr>
                                </>
                            )
                            }

                            </tbody>
                        </table>
                        <h1>Buchungen</h1>
                        {
                            bookings.map((row) =>
                                <table className="table table-responsive table-striped">
                                    <thead>
                                    <tr>
                                        <th>Buchung: {row.BuLiNummer}</th>
                                        <th>Teilnehmer:</th>
                                        <th>Unterkunft</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {row.Teilnehmer.map((m) =>
                                        <tr>
                                            <td></td>
                                            <td>{m.Vorname} {m.Name}</td>
                                            <td>{m.BEZ_LANG} ({m.BEZ_KURZ})</td>
                                        </tr>
                                    )
                                    }
                                    </tbody>
                                </table>
                            )
                        }
                    </div>
                    <div className="modal-footer">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookingDetailModal;
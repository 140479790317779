import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {useAuth} from "../../context/AuthContext";
import HeaderSubPageTiny from "../partial/HeaderSubPageTiny";
import {fetchAgency} from "../utils/utils";
import {ToastContainer} from "react-toastify";
import Reports from "./Reports";
import Contingents from "./Contingents";
import Profile from "./Profile";
import Booked from "./Booked";
import BookingDetailModal from "./BookingDetailModal";

const Image = process.env.PUBLIC_URL + "/images/headers/Kontakt-MannamTelefon.png";

const Dashboard = ({partner}) => {
    const auth = useAuth();
    const [agency, setAgency] = useState(null);
    const [contingents, setContingents] = useState([]);
    const [openedModalIds, setOpenedModalIds] = useState([]);

    function handleContingentDataFromChild(data, openedModals) {
        setContingents(data);

    }

    function handleModalOpenedIdsFromChild(id){
        setOpenedModalIds([...openedModalIds, id])
    }

    useEffect(() => {
        fetchAgency(auth.user.Kundennummer, auth.token).then((response) => {
            setAgency(response)
        }).catch((e) => {
            auth.logOut(); // todo: better logic
            //notify("Agentur wurde nicht gefunden", "warning")
        });

        document.title = `${partner.name.toUpperCase()} - Dashboard`;
    }, [])

    return (
        <div>
            <ToastContainer/>
            <div className="site wrapper-content">
                <HeaderSubPageTiny title={'Kontakt'} subtitle={'SO ERREICHEN SIE UNS'}
                                   bgImage={Image}></HeaderSubPageTiny>
                <section className="content-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active " id="profile-tab" data-bs-toggle="tab"
                                                data-bs-target="#profile" type="button" role="tab"
                                                aria-controls="profile"
                                                aria-selected="true"><h3><i className="fa fa-user-circle"></i> Profile
                                        </h3>
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="report-tab" data-bs-toggle="tab"
                                                data-bs-target="#report" type="button" role="tab"
                                                aria-controls="report"
                                                aria-selected="false"><h3><i className="fa fa-file-pdf-o"></i> Forecast
                                            Provisionen</h3>
                                        </button>
                                    </li>
                                    {/*<li className="nav-item" role="presentation">
                                        <button className="nav-link" id="contingent-tab" data-bs-toggle="tab"
                                                data-bs-target="#contingent" type="button" role="tab"
                                                aria-controls="contingent"
                                                aria-selected="false"><h3><i className="fa fa-database"></i> Kontingente
                                        </h3>
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="booked-tab" data-bs-toggle="tab"
                                                data-bs-target="#booked" type="button" role="tab"
                                                aria-controls="booked"
                                                aria-selected="false"><h3><i className="fa fa-list"></i> Buchungen</h3>
                                        </button>
                                    </li>*/}
                                </ul>

                                <div className="tab-content p-5" id="myTabContent">

                                    <Profile agency={agency}></Profile>

                                    <Reports agency={agency}></Reports>

                                    <Contingents agency={agency}></Contingents>

                                    <Booked agency={agency} sendContingentDataToParent={handleContingentDataFromChild} sendModalOpenedIdToParent={handleModalOpenedIdsFromChild}></Booked>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {contingents.map((contingent) => <BookingDetailModal contingent={contingent} openedModals={openedModalIds}></BookingDetailModal>)}
        </div>
    )
}

export default Dashboard;